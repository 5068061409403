import React from "react";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  setCounty,
  setcounty,
  setFilter,
} from "../../store/controls/controls-action";
import { selectcounty } from "../../store/controls/controls-select";
import { getAllRois, getHome } from "../../store/rois/rois-select";

const animatedComponents = makeAnimated();

const CountySelect = () => {
  const dispatch = useDispatch();

  const [dataSelectcounty, dataSetSelectcounty] = useState({});
  const handleSelect = (e) => {
    if (e != null) {
      // dataSetSelectcounty(e);
      dispatch(setCounty(e.value));
      dispatch(setFilter());
    } 
    else {
      // dataSetSelectcounty(e);
      dispatch(setCounty(""));
      dispatch(setFilter());
    }
  };

  const [countyData, setCountyData] = useState({});
  const rois = useSelector((state) => getAllRois(state));

  useEffect(() => {
    async function fetchEmployees() {
      const filterAllCounty = [
        ...new Set(rois?.map((item) => item.home.county)),
      ];

      const allCounty = [];
      filterAllCounty.forEach((item, i) => {
        allCounty.push({ value: item, label: item });
      });
      setCountyData(allCounty);
    }
    fetchEmployees();
  }, []);

  return (
    <Select
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      components={animatedComponents}
      defaultValue={countyData[0]}
      placeholder="County"
      options={countyData}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default CountySelect;
