import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/card/Card";
import "./detail.scss";
import Calculation from "../../components/calculation/Calculation";

const Detail = () => {
  const [roi, setRoi] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    debugger;
    fetch(`https://4744.freelancedeveloper.site/api/rois/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setRoi(data);
      });
  }, [id]);
  let home = roi && roi[0] && roi[0].home;
  return (
    <div className="detail">
      <div className="single">
        <Card {...home} />
      </div>
      <div className="calculation">
        {roi !== null ? <Calculation props={roi && roi[0]} /> : null}
      </div>
    </div>
  );
};

export default Detail;
