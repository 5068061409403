import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

const Favourites = () => {
	const { isLoggedIn } = useSelector((state) => state.auth)
	// if (!isLoggedIn) {
	// 	return <Navigate to="/login" />
	// }
	return (
		<div>
			{isLoggedIn ? (
				<h1>Favourites</h1>
			) : (
				<h2>Please Log In to see your favourites list.</h2>
			)}
		</div>
	)
}

export default Favourites
