import { setFilter, setLoadProducts } from "../controls/controls-action";

export const SET_ROIS = "@@rois/SET_ROIS";
export const SET_ROIS_BY_ID = "@@rois/SET_ROIS_BY_ID";
export const SET_LOADING = "@@rois/SET_LOADING";
export const SET_ERROR = "@@rois/SET_ERROR";
export const SET_FILTER = "@@rois/SET_FILTER";

export const setRois = (roi) => ({
  type: SET_ROIS,
  payload: roi,
});
export const setRoisById = (id) => ({
  type: SET_ROIS_BY_ID,
  payload: id,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

export const loadRois =
  () =>
  (dispatch, _, { client, api }) => {
    dispatch(setLoading());

    client
      .get(api.ALL_ROIS)
      .then(({ data }) =>
        dispatch(setRois(data), dispatch(setLoadProducts(data)))
      )
      .catch((err) => dispatch(setError(err.message)));
  };
