import React, { useEffect, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { clearMessage } from "./store/auth/message-action"
import Header from "./components/header/Header"
import Main from "./components/main/Main"
import Footer from "./components/footer/Footer"
import Home from "./pages/home/Home"
import Login from "./pages/login/Login"
import Detail from "./pages/detail/Detail"
import Register from "./pages/register/Register"
import "./App.scss"
import Favourites from "./pages/favourites/Favourites"
import { ToastContainer } from "react-toastify"

function App() {
	const dispatch = useDispatch()

	let location = useLocation()

	useEffect(() => {
		if (["/login", "/register"].includes(location.pathname)) {
			dispatch(clearMessage())
		}
	}, [dispatch, location])

	return (
		<div className="layout">
			<ToastContainer />
			<div className="container">
				<header>
					<Header />
				</header>
			</div>
			<main>
				<div className="container">
					<Main>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/home" element={<Home />} />
							<Route path="/home/:id" element={<Detail />} />
							<Route path="/login" element={<Login />} />
							<Route path="/register" element={<Register />} />
							<Route path="/favourites" element={<Favourites />} />
						</Routes>
					</Main>
				</div>
			</main>
			<div className="container">
				<footer>
					<Footer />
				</footer>
			</div>
		</div>
	)
}

export default App
