import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
} from "./auth-action"

const client = JSON.parse(localStorage.getItem("client"))

const initialState = client
	? { isLoggedIn: true, client }
	: { isLoggedIn: false, client: null }

export default function authReducer(state = initialState, { type, payload }) {
	switch (type) {
		case REGISTER_SUCCESS:
			return {
				...state,
				isLoggedIn: false,
			}
		case REGISTER_FAIL:
			return {
				...state,
				isLoggedIn: false,
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				client: payload,
			}
		case LOGIN_FAIL:
			return {
				...state,
				isLoggedIn: false,
				client: null,
			}
		case LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				client: null,
			}
		default:
			return state
	}
}
