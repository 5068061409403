export const SET_SEARCH = "@@controls/SET_SEARCH";
export const SET_ADDRESS = "@@controls/SET_ADDRESS";
export const SET_RANGE = "@@controls/SET_RANGE";
export const SET_BEDROOMS = "@@controls/SET_BEDROOMS";
export const SET_BATHROOMS = "@@controls/SET_BATHROOMS";
export const SET_COUNTY = "@@controls/SET_COUNTY";
export const SET_STATES = "@@controls/SET_STATES";
export const SET_COUNTRY = "@@controls/SET_COUNTRY";
export const CLEAR_CONTROLS = "@@controls/CLEAR_CONTROLS";
export const LOAD_PRODUCTS = "@@controls/LOAD_PRODUCTS";
export const SET_SORT = "@@controls/SET_SORT";
export const FILTER_PRODUCTS = "@@controls/FILTER_PRODUCTS,";

export const setLoadProducts = (data) => ({
  type: LOAD_PRODUCTS,
  payload: data,
});

export const setSearch = (search) => ({
  type: SET_SEARCH,
  payload: search,
});

export const setAddress = (address) => ({
  type: SET_ADDRESS,
  payload: address,
});
export const setRange = (range) => ({
  type: SET_RANGE,
  payload: range,
});

export const setBedrooms = (bedrooms) => ({
  type: SET_BEDROOMS,
  payload: bedrooms,
});

export const setBathrooms = (bathrooms) => ({
  type: SET_BATHROOMS,
  payload: bathrooms,
});

export const setCounty = (county) => ({
  type: SET_COUNTY,
  payload: county,
});

export const setStates = (states) => ({
  type: SET_STATES,
  payload: states,
});

export const setCountry = (country) => {
  return {
    type: SET_COUNTRY,
    payload: country,
  };
};

export const setSort = (sort) => {
  return {
    type: SET_SORT,
    payload: sort,
  };
};

export const setFilter = () => {
  return {
    type: FILTER_PRODUCTS,
  };
};
export const clearControls = () => ({
  type: CLEAR_CONTROLS,
});
