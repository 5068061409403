import { combineReducers } from "redux"
import authReducer from "./auth/auth-reducer"
import messageReducer from "./auth/message-reducer"
import { roisReducer } from "./rois/rois-reducer"
import { controlsReducer } from "./controls/controls-reducer"

export const rootReducer = combineReducers({
	rois: roisReducer,
	controls: controlsReducer,
	auth: authReducer,
	message: messageReducer,
})
