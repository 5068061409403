import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { setBedrooms, setFilter } from "../../store/controls/controls-action";
import { selectBedrooms } from "../../store/controls/controls-select";
import { getAllRois, getHome } from "../../store/rois/rois-select";

const animatedComponents = makeAnimated();

const BedroomsSelect = () => {
  const dispatch = useDispatch();
  const bedrooms = useSelector(selectBedrooms);
  console.log("bedrooms :", bedrooms);
  const [dataselectBedrooms, dataSetselectBedrooms] = useState({});
  const handleSelect = (e) => {
    if (e != null) {
      dispatch(setBedrooms(e.value));
      dispatch(setFilter());
    } else {
      dispatch(setBedrooms(0));
      dispatch(setFilter());
    }
  };

  const [bedroomsData, setBedroomsData] = useState({});
  const rois = useSelector((state) => getAllRois(state));

  useEffect(() => {
    async function fetchEmployees() {
      const filterAllbedrooms = [
        ...new Set(rois?.map((item) => item.home.countOfBadrooms)),
      ];

      const allbedrooms = [];
      filterAllbedrooms.forEach((item, i) => {
        allbedrooms.push({ value: item, label: item });
      });
      setBedroomsData(allbedrooms);
    }
    fetchEmployees();
  }, []);

  return (
    <Select
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      components={animatedComponents}
      defaultValue={bedroomsData[0]}
      placeholder="Number of bedrooms"
      options={bedroomsData}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default BedroomsSelect;
