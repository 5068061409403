import axios from "axios"
import authHeader from "./auth-header"

const API_URL = "https://4744.freelancedeveloper.site/api/"

const register = (name, email, password) => {
	return axios.post(API_URL + "register", {
		name,
		email,
		password,
	})
}

const login = (email, password) => {
	return axios
		.post(API_URL + "login", {
			email,
			password,
		})
		.then((response) => {
			if (response.data) {
				localStorage.setItem("client", JSON.stringify(response.data))
			}
			return response.data
		})
}
const logout = () => {
	localStorage.removeItem("client")
}

export default {
	register,
	login,
	logout,
}
