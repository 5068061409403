import {
  SET_ROIS,
  SET_ERROR,
  SET_LOADING,
  SET_ROIS_BY_ID,
  SET_FILTER,
} from "./rois-action";

const initialState = {
  status: "idle",
  list: [],
  home: {},
  error: null,
  filterList: [],
};

export const roisReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ROIS:
      return {
        ...state,
        list: payload,
        filterList: payload,
        status: "succes",
      };
    case SET_ROIS_BY_ID:
      return {
        ...state,
        ...state.list,
        home: {
          ...state.list.filter((item) => item.id == payload),
        },
      };
    case SET_ERROR:
      return {
        ...state,
        status: "faild",
        error: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        status: "loading",
        error: null,
      };
    case SET_FILTER:
      return {
        ...state,
        filterList: payload,
      };
    default:
      return state;
  }
};
