import React from "react"
import { ReactComponent as Logo } from "../../assets/icon/logo.svg"
import bg from "../../assets/icon/bg.png"
import "./form.scss"

const FormComponent = ({ title, description, children }) => {
	return (
		<div className="auth-container">
			<div className="card-content">
				<div className="card-container">
					<div className="card-header">
						<Logo width="134px" height="67px" />
						<h2 className="card-header-title">{title}</h2>
						{description ? (
							<p>
								Please fill your Email and we will send password recovery link
								to Your Email address.
							</p>
						) : null}
					</div>
					<div className="card-body">{children}</div>
				</div>
			</div>
			<div className="card-image">
				<img src={bg} alt="" width="1083px" height="901px" />
			</div>
		</div>
	)
}

export default FormComponent
