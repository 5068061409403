import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/card/Card";
import Filter from "../../components/filter/Filter";
import Loader from "../../components/loader/Loader";
import Row from "../../components/row/Row";
import {
  selectCountry,
  selectCounty,
  selectFilterProduct,
  selectRange,
  selectSearch,
} from "../../store/controls/controls-select";
import { loadRois } from "../../store/rois/rois-action";
import {
  getAllRois,
  getRois,
  getVisibleHomes,
} from "../../store/rois/rois-select";
import NotFound from "../not-found/NotFound";
import "./home.scss";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const range = useSelector(selectRange);
  const county = useSelector(selectCounty);
  const { status, error, qty } = useSelector(getRois);

  const rois = useSelector(selectFilterProduct);
  // const rois = useSelector((state) =>
  //   getVisibleHomes(state, { search, range, county })
  // );
  const navigate = useNavigate();

  useEffect(() => {
    if (!qty) {
      dispatch(loadRois());
    }
  }, [qty, dispatch]);

  return (
    <>
      {status === "loading" ? (
        <Loader />
      ) : (
        <div>
          <div className="header">
            <h1 className="header--title">Homes for you</h1>
            <Filter />
          </div>
          {rois.length ? (
            <Row col="col-4">
              {rois.map((item, index) => {
                let homes = item.home;
                let id = item.id;
                return (
                  <Card
                    key={index}
                    {...homes}
                    onClick={() => navigate(`/home/${id}`)}
                  />
                );
              })}
            </Row>
          ) : (
            <NotFound />
          )}
        </div>
      )}
    </>
  );
};

export default Home;
