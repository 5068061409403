import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Range, getTrackBackground } from "react-range"
import { selectRange } from "../../store/controls/controls-select"
import { setFilter, setRange } from "../../store/controls/controls-action"

const STEP = 100
const MIN = 0
const MAX = 1500000

const TwoThumbs = ({ rtl }) => {
	const range = useSelector(selectRange)

	const dispatch = useDispatch()

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexWrap: "wrap",
			}}
		>
			<Range
				values={range}
				step={STEP}
				min={MIN}
				max={MAX}
				rtl={rtl}
				onChange={(values) => {
					dispatch(setRange(values))
					dispatch(setFilter());
				}}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: "16px",
							display: "flex",
							width: "100%",
						}}
					>
						<div
							ref={props.ref}
							style={{
								height: "2px",
								width: "100%",
								borderRadius: "50%",
								background: getTrackBackground({
									values: range,
									colors: ["#3AABC2", "#3AABC2", "#3AABC2"],
									min: MIN,
									max: MAX,
									rtl,
								}),
								alignSelf: "center",
							}}
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props, isDragged }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: "16px",
							width: "16px",
							borderRadius: "50%",
							backgroundColor: "#3AABC2",
							boxShadow: "none",
							// display: "flex",
							// justifyContent: "center",
							// alignItems: "center",
							// boxShadow: "0px 2px 6px #AAA",
						}}
					>
						<div
							style={{
								height: "16px",
								width: "16px",
								borderRadius: "50%",
								backgroundColor: isDragged && "#3AABC2",
								// backgroundColor: isDragged ? "#548BF4" : "#CCC",
							}}
						/>
					</div>
				)}
			/>
			<output style={{ marginTop: "30px" }} id="output">
				{range[0]} - {range[1]}
			</output>
		</div>
	)
}

export default TwoThumbs
