import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as MapIcon } from "../../assets/icon/map.svg";
import imgSrc from "../../assets/icon/home.png";
import "./card.scss";
import { FiHeart } from "react-icons/fi";
const Card = ({ id, address, purchasePrice, title, roi, onClick }) => {
  return (
    <div className="card">
      <div className="card--img">
        <img src={imgSrc} alt="" width="384px" height="244px" />
      </div>
      <div className="card--body">
        <h5 className="card--title">$ {purchasePrice}</h5>
        <FiHeart
          className="heart"
          color="#3AABC2"
          fontSize="20px"
          width="20px"
        />

        <div className="card--details">
          <small>{title}</small>
          {/* <i></i>
					<small>2 bth</small>
					<i></i>
					<small>Kissimmee, FL</small>
					<i></i>
					<small>USA</small>
					<i></i>
					<small>Active</small> */}
        </div>
        <address>
          <MapIcon width="24px" height="24px" />
          <button onClick={onClick}>{address}</button>
        </address>
      </div>
    </div>
  );
};

export default Card;
