import React from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { setFilter, setSort } from "../../store/controls/controls-action";
const optionsSort = {
  HighestROI: { value: "price-highest", label: "Highest ROI" },
  LowestROI: { value: "price-lowest", label: "Lowest ROI" },
};

const options = Object.values(optionsSort);

const SortSelect = () => {
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    if (e !== null) {
      dispatch(setSort(e.value));
      dispatch(setFilter());
    } else {
      dispatch(setSort(""));
      dispatch(setFilter());
    }
  };
  return (
    <ReactSelect
      // menuIsOpen={true}
      isFocused={false}
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      placeholder="Sort by"
      options={options}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default SortSelect;
