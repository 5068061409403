import React from "react"
import "./button.scss"

const Button = ({ name, className, onClick, disabled }) => {
	return (
		<button
			className={`btn ${className}`}
			onClick={onClick}
			disabled={disabled}
		>
			{name}
		</button>
	)
}

export default Button
