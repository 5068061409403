import React from "react"
import { Link } from "react-router-dom"
import "./footer.scss"

const Footer = () => {
	return (
		<div className="footer--row">
			<p className="footer-description">
				© 2022 Company Name, All rights reserved
			</p>
			<div className="footer-law">
				<Link>Privacy Policy</Link>
				<span className="footer-line"></span>
				<Link>Terms of Use</Link>
			</div>
		</div>
	)
}

export default Footer
