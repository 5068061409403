import React from "react"
import "./input.scss"

const Input = ({ onChange, value }) => {
	return (
		<div className="calc-input">
			<span>$</span>
			<input
				type="number"
				value={value}
				onChange={onChange}
				className="calc--input"
			/>
		</div>
	)
}

export default Input
