import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

import Loader from "./../../components/loader/Loader"
import Form from "react-validation/build/form"
import Input from "react-validation/build/input"
import CheckButton from "react-validation/build/button"

import { login } from "../../store/auth/auth-action"
import Button from "../../components/button/Button"
import FormComponent from "../../components/form/Form"

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		)
	}
}

const Login = (props) => {
	let navigate = useNavigate()

	const form = useRef()
	const checkBtn = useRef()

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)

	const { isLoggedIn } = useSelector((state) => state.auth)
	const { message } = useSelector((state) => state.message)

	const dispatch = useDispatch()

	const onChangeEmail = (e) => {
		const email = e.target.value
		setEmail(email)
	}

	const onChangePassword = (e) => {
		const password = e.target.value
		setPassword(password)
	}

	const handleLogin = (e) => {
		e.preventDefault()

		setLoading(true)

		form.current.validateAll()

		if (checkBtn.current.context._errors.length === 0) {
			dispatch(login(email, password))
				.then(() => {
					navigate("/")
					// window.location.reload()
				})
				.catch(() => {
					setLoading(false)
				})
		} else {
			setLoading(false)
		}
	}

	if (isLoggedIn) {
		return <Navigate to="/" />
	}

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<FormComponent title="Welcome back!">
					<Form onSubmit={handleLogin} ref={form}>
						<div className="form-group">
							<Input
								type="text"
								className="form-control"
								name="email"
								placeholder="Enter your email address"
								value={email}
								onChange={onChangeEmail}
								validations={[required]}
							/>
						</div>

						<div className="form-group">
							<Input
								type="password"
								className="form-control"
								name="password"
								placeholder="Enter your password"
								value={password}
								onChange={onChangePassword}
								validations={[required]}
							/>
						</div>

						<div className="form-group">
							<Button
								className="btn-full w-100"
								disabled={loading}
								name="Login"
							/>
						</div>

						{message && (
							<div className="form-group">
								<div className="alert alert-danger" role="alert">
									{message}
								</div>
							</div>
						)}
						<CheckButton style={{ display: "none" }} ref={checkBtn} />
					</Form>
				</FormComponent>
			)}
		</>
	)
}

export default Login
