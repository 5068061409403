import { SET_MESSAGE, CLEAR_MESSAGE } from "./message-action"

const initialState = {}

export default function messageReducer(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case SET_MESSAGE:
			return { message: payload }

		case CLEAR_MESSAGE:
			return { message: "" }

		default:
			return state
	}
}
