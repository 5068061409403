import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import { ReactComponent as FavouritesIcon } from "../../assets/icon/favourite.svg"
import { ReactComponent as Logo } from "../../assets/icon/logo.svg"

import Button from "../button/Button"
import Dropdown from "../dropdown/Dropdown"
import "./header.scss"

const Header = () => {
	const { isLoggedIn, client } = useSelector((state) => state.auth)

	const navigate = useNavigate()
	return (
		<div className="header--row">
			<div className="header--favourites">
				<Link to="/favourites">
					<FavouritesIcon width="24px" height="24px" />
					Favourites
				</Link>
			</div>
			<div className="header--logo">
				<Link to="/">
					<Logo width="111px" height="56px" />
				</Link>
			</div>
			{isLoggedIn ? (
				<Dropdown name={client?.data?.user?.name ?? null} />
			) : (
				<div className="header--btn">
					<Button
						name="Log In"
						className="btn-white"
						onClick={() => {
							navigate("/login")
						}}
					/>
					<Button
						name="Sign Up"
						className="btn-outline"
						onClick={() => {
							navigate("/register")
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default Header
