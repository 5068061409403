import React from "react"
import ReactLoading from "react-loading"
import "./loader.scss"

const Loader = () => {
	return (
		<div className="loader">
			<ReactLoading type={"spin"} color={"#3AABC2"} />
		</div>
	)
}

export default Loader
