import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { setBathrooms, setFilter } from "../../store/controls/controls-action";
import { selectbathrooms } from "../../store/controls/controls-select";
import { getAllRois, getHome } from "../../store/rois/rois-select";

const animatedComponents = makeAnimated();

const BathroomsSelect = () => {
  const dispatch = useDispatch();

  const [dataselectbathrooms, dataSetselectbathrooms] = useState({});
  const handleSelect = (e) => {
    if (e != null) {
      dispatch(setBathrooms(e.value));
      dispatch(setFilter());
    } else {
      dispatch(setBathrooms(0));
      dispatch(setFilter());
    }
  };

  const [bathroomsData, setbathroomsData] = useState({});
  const rois = useSelector((state) => getAllRois(state));

  useEffect(() => {
    async function fetchEmployees() {
      const filterAllbathrooms = [
        ...new Set(rois?.map((item) => item.home.countOfBathrooms)),
      ];

      const allbathrooms = [];
      filterAllbathrooms.forEach((item, i) => {
        allbathrooms.push({ value: item, label: item });
      });
      setbathroomsData(allbathrooms);
    }
    fetchEmployees();
  }, []);

  return (
    <Select
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      components={animatedComponents}
      defaultValue={bathroomsData[0]}
      placeholder="Number of bathrooms"
      options={bathroomsData}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default BathroomsSelect;
