import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Form from "react-validation/build/form"
import Input from "react-validation/build/input"
import CheckButton from "react-validation/build/button"
import { isEmail } from "validator"

import { register } from "../../store/auth/auth-action"
import FormComponent from "../../components/form/Form"
import Button from "../../components/button/Button"

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		)
	}
}

const validEmail = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="alert alert-danger" role="alert">
				This is not a valid email.
			</div>
		)
	}
}

const vusername = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="alert alert-danger" role="alert">
				The username must be between 3 and 20 characters.
			</div>
		)
	}
}

const vpassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		)
	}
}

const Register = () => {
	const form = useRef()
	const checkBtn = useRef()

	const [name, setUsername] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [successful, setSuccessful] = useState(false)

	const { message } = useSelector((state) => state.message)
	const dispatch = useDispatch()

	const onChangeUsername = (e) => {
		const name = e.target.value
		setUsername(name)
	}

	const onChangeEmail = (e) => {
		const email = e.target.value
		setEmail(email)
	}

	const onChangePassword = (e) => {
		const password = e.target.value
		setPassword(password)
	}

	const handleRegister = (e) => {
		e.preventDefault()

		setSuccessful(false)

		form.current.validateAll()

		if (checkBtn.current.context._errors.length === 0) {
			dispatch(register(name, email, password))
				.then(() => {
					setSuccessful(true)
				})
				.catch(() => {
					setSuccessful(false)
				})
		}
	}

	return (
		<FormComponent title="Join Us">
			{!successful && (
				<Form onSubmit={handleRegister} ref={form}>
					<div className="form-group">
						<Input
							type="text"
							className="form-control"
							name="name"
							value={name}
							placeholder="Enter your full name"
							onChange={onChangeUsername}
							validations={[required, vusername]}
						/>
					</div>

					<div className="form-group">
						<Input
							type="text"
							className="form-control"
							name="email"
							placeholder="Enter your email address"
							value={email}
							onChange={onChangeEmail}
							validations={[required, validEmail]}
						/>
					</div>

					<div className="form-group">
						<Input
							type="password"
							className="form-control"
							name="password"
							value={password}
							placeholder="Enter your password"
							onChange={onChangePassword}
							validations={[required, vpassword]}
						/>
					</div>

					<div className="form-group">
						<Button className="btn-full w-100" name="Sign Up" />
					</div>

					{message && (
						<div className="alert">
							<div
								className={
									successful ? "alert alert-success" : "alert alert-danger"
								}
								role="alert"
							>
								{message}
							</div>
						</div>
					)}
					<CheckButton style={{ display: "none" }} ref={checkBtn} />
				</Form>
			)}
		</FormComponent>
	)
}

export default Register
