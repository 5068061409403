import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import Input from "../input/Input";
import "./calculation.scss";

const Calculation = ({ props }) => {
  const navigate = useNavigate();
  const {
    arv,
    wireTransferFees,
    utility,
    updated_at,
    totalCashUpFront,
    taxes,
    survey,
    squareFootage,
    revenue,
    resortFees,
    rehubBudget,
    pitiDuringRehab,
    piti,
    ofAcres,
    netOperIncome,
    monthlyRevenue,
    lawnCarePestControl,
    id,
    homeInspirationCost,
    hoa,
    fdt,
    downPaymentClosingCost,
    created_at,
    cashOutRedinanceAmount,
    cashOnCashReturnOnInvest,
    home,
  } = props;

  const [formData, setFormData] = useState({
    purchasePrice: home.purchasePrice,
    rehubBudget: rehubBudget,
    projectedAnnualRevenue: "",
    cleaning: utility?.cleaning,
    gas: utility.gas,
    electric: utility.electric,
    sewer: utility.sewer,
    water: utility.water,
    internet: utility.internet,
    miscellaneous: utility.miscellaneous ?? "",
  });

  const handleSubmit = async (e) => {
    // store the states in the form data
    e.preventDefault();
    const newFormData = new FormData();
    newFormData.append("purchasePrice", formData.purchasePrice);
    newFormData.append("rehubBudget", formData.rehubBudget);
    newFormData.append(
      "projectedAnnualRevenue",
      formData.projectedAnnualRevenue
    );
    newFormData.append("cleaning", formData.cleaning);
    newFormData.append("gas", formData.gas);
    newFormData.append("electric", formData.electric);
    newFormData.append("sewer", formData.sewer);
    newFormData.append("water", formData.water);
    newFormData.append("internet", formData.internet);
    newFormData.append("miscellaneous", formData.miscellaneous);
    debugger;
    try {
      // make axios post request
      const response = await axios({
        method: "post",
        url: `https://4744.freelancedeveloper.site/api/calculate/${id}`,
        data: newFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="calc">
      <div className="calc-header">
        <div className="rows">
          <div className="col">Name</div>
          <div className="col">Value</div>
          <div className="col">Name</div>
          <div className="col">Value</div>
        </div>
      </div>
      <div className="calc-body">
        <form action="" onSubmit={handleSubmit}>
          <div className="rows">
            <div className="col">Purchase Price</div>
            <div className="col">
              <Input
                value={formData.purchasePrice}
                onChange={(e) =>
                  setFormData({ ...formData, purchasePrice: e.target.value })
                }
              />
            </div>
            <div className="col">{`HOA (if appliable)`}</div>
            <div className="col">${hoa}</div>
          </div>
          <div className="rows">
            <div className="col">Furniture/Decor/Travel</div>
            <div className="col">${fdt}</div>
            <div className="col">Cleaning</div>
            <div className="col">
              <Input
                value={formData.cleaning}
                onChange={(e) =>
                  setFormData({ ...formData, cleaning: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">Rehab Budget</div>
            <div className="col">
              <Input
                value={formData.rehubBudget}
                onChange={(e) =>
                  setFormData({ ...formData, rehubBudget: e.target.value })
                }
              />
            </div>
            <div className="col">Electric</div>
            <div className="col">
              <Input
                value={formData.electric}
                onChange={(e) =>
                  setFormData({ ...formData, electric: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">PITI During Rehab</div>
            <div className="col">${pitiDuringRehab}</div>
            <div className="col">Gas</div>
            <div className="col">
              <Input
                value={formData.gas}
                onChange={(e) =>
                  setFormData({ ...formData, gas: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">ARV</div>
            <div className="col">${arv}</div>
            <div className="col">Lawn Cre/Pest Control</div>
            <div className="col">${lawnCarePestControl}</div>
          </div>
          <div className="rows">
            <div className="col">Cash Out Refinance Amount</div>
            <div className="col">${cashOutRedinanceAmount}</div>
            <div className="col">PITI</div>
            <div className="col">${piti}</div>
          </div>
          <div className="rows">
            <div className="col">Home Inspection Cost</div>
            <div className="col">${homeInspirationCost}</div>
            <div className="col">Resort Fees</div>
            <div className="col">${resortFees}</div>
          </div>
          <div className="rows">
            <div className="col">Survey</div>
            <div className="col">${survey}</div>
            <div className="col">Sewer</div>
            <div className="col">
              <Input
                value={formData.sewer}
                onChange={(e) =>
                  setFormData({ ...formData, sewer: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">{`Wire Transfer Fees ($20 each)`}</div>
            <div className="col">${wireTransferFees}</div>
            <div className="col">Taxes</div>
            <div className="col">${taxes}</div>
          </div>
          <div className="rows">
            <div className="col">Down Payment/Closing Costs</div>
            <div className="col">${downPaymentClosingCost}</div>
            <div className="col">Water</div>
            <div className="col">
              <Input
                value={formData.water}
                onChange={(e) =>
                  setFormData({ ...formData, water: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">Total Cash up Front</div>
            <div className="col">${totalCashUpFront}</div>
            <div className="col">Internet</div>
            <div className="col">
              <Input
                value={formData.internet}
                onChange={(e) =>
                  setFormData({ ...formData, internet: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">Projected Annual Revenue</div>
            <div className="col">
              <Input
                value={formData.projectedAnnualRevenue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    projectedAnnualRevenue: e.target.value,
                  })
                }
              />
            </div>
            <div className="col">Miscellaneous</div>
            <div className="col">
              <Input
                value={formData.miscellaneous}
                onChange={(e) =>
                  setFormData({ ...formData, miscellaneous: e.target.value })
                }
              />
            </div>
          </div>
          <div className="rows">
            <div className="col">Monthly Revenue</div>
            <div className="col">${monthlyRevenue}</div>
            <div className="col">Net Oper. Income</div>
            <div className="col">${netOperIncome}</div>
          </div>
          <div className="rows">
            <div className="col">Square Footage</div>
            <div className="col">{squareFootage}</div>
            <div className="col">Cash on Cash Return-on-Investment</div>
            <div className="col">%{cashOnCashReturnOnInvest}</div>
          </div>
          <div className="rows">
            <div className="col"># of Acres</div>
            <div className="col">{ofAcres}</div>
            <div className="calc-btn">
              <Button
                className="btn-full"
                name="Calculate"
                // onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Calculation;
