import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { setFilter, setStates } from "../../store/controls/controls-action";
// import { selectStates } from "../../store/controls/controls-select";
import { getAllRois, getHome } from "../../store/rois/rois-select";

const animatedComponents = makeAnimated();

const StateSelect = () => {
  const dispatch = useDispatch();

  const [dataSelectstates, dataSetSelectstates] = useState({});
  const handleSelect = (e) => {
    if (e != null) {
      dispatch(setStates(e.value));
      dispatch(setFilter());
    } else {
      dispatch(setStates(""));
      dispatch(setFilter());
    }
  };

  const [statesData, setStatesData] = useState({});
  const rois = useSelector((state) => getAllRois(state));

  useEffect(() => {
    async function fetchEmployees() {
      const filterAllstates = [
        ...new Set(rois?.map((item) => item.home.state)),
      ];
      console.log("filterAllstates :", filterAllstates);

      const allstates = [];
      filterAllstates.forEach((item, i) => {
        allstates.push({ value: item, label: item });
      });
      setStatesData(allstates);
    }
    fetchEmployees();
  }, []);

  return (
    <Select
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      components={animatedComponents}
      placeholder="States"
      defaultValue={statesData[0]}
      options={statesData}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default StateSelect;
