export const getRois = (state) => ({
  status: state.rois.status,
  error: state.rois.error,
  qty: state.rois.length,
});

export const getHome = (state) => state.rois.home;
export const getAllRois = (state) => state.rois.list;

export const getRoisById = (state, { id }) => ({
  roi: state.rois.list.filter((roi) => roi.id === Number(id)),
});

export const dataById = (state, id) => {
  return state.rois.list.filter((roi) => {
    return roi.id === Number(id)
  })
}