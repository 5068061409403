import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { setCountry, setFilter } from "../../store/controls/controls-action";
import { selectCountry } from "../../store/controls/controls-select";
import { getAllRois, getHome } from "../../store/rois/rois-select";

const animatedComponents = makeAnimated();

const CountrySelect = () => {
  const dispatch = useDispatch();

  const [dataSelectCountry, dataSetSelectCountry] = useState({});
  const handleSelect = (e) => {
    if (e != null) {
      dispatch(setCountry(e.value));
      dispatch(setFilter());
    } else {
      dispatch(setCountry(""));
      dispatch(setFilter());
    }
  };

  const [countryData, setCountryData] = useState({});
  const rois = useSelector((state) => getAllRois(state));

  useEffect(() => {
    async function fetchEmployees() {
      const filterAllCountry = [
        ...new Set(rois?.map((item) => item.home.country)),
      ];

      const allCountry = [];
      filterAllCountry.forEach((item, i) => {
        allCountry.push({ value: item, label: item });
      });
      setCountryData(allCountry);
    }
    fetchEmployees();
  }, []);

  return (
    <Select
      classNamePrefix="custom--select-prefix"
      className="custom--select"
      loadingMessage={false}
      isSearchable={false}
      autoFocus={false}
      blurInputOnSelect={false}
      components={animatedComponents}
      defaultValue={countryData[0]}
      placeholder="Country"
      options={countryData}
      onChange={handleSelect}
      isClearable={true}
    />
  );
};

export default CountrySelect;
