import React, { useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { logout } from "../../store/auth/auth-action"
import { ReactComponent as ProfileIcon } from "../../assets/icon/profile.svg"
import { ReactComponent as PasswordIcon } from "../../assets/icon/password.svg"
import { ReactComponent as SignOutIcon } from "../../assets/icon/sign-out.svg"
import { ReactComponent as UserIcon } from "../../assets/icon/user.svg"
import "./dropdown.scss"

const Dropdown = ({ name }) => {
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const handleDropdown = () => {
		setIsOpen((e) => !e)
	}
	const logOut = useCallback(() => {
		dispatch(logout())
	}, [dispatch])

	return (
		<div className="dropdown">
			<button className="dropdown--btn" onClick={handleDropdown}>
				<UserIcon />
				Hi, {name}!
			</button>
			<div className={`dropdown--menu ${isOpen ? "show" : ""}`}>
				<Link to="/profile" className="dropdown--item">
					<ProfileIcon />
					Profile
				</Link>
				<Link to="/password" className="dropdown--item">
					<PasswordIcon />
					Password
				</Link>
				<Link to="#" className="dropdown--item" onClick={logOut}>
					<SignOutIcon />
					Sign Out
				</Link>
			</div>
		</div>
	)
}

export default Dropdown
