import {
  CLEAR_CONTROLS,
  SET_ADDRESS,
  SET_RANGE,
  SET_SEARCH,
  SET_BEDROOMS,
  SET_BATHROOMS,
  SET_COUNTY,
  SET_STATES,
  SET_COUNTRY,
  LOAD_PRODUCTS,
  FILTER_PRODUCTS,
  SET_SORT,
} from "./controls-action";

const initialState = {
  filtered_products: [],
  all_products: [],
  search: "",
  address: "",
  country: "",
  county: "",
  bedrooms: 0,
  bathrooms: 0,
  states: "",
  range: [0, 1500000],
  sort: "",
};

export const controlsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_PRODUCTS:
      return {
        ...state,
        all_products: [...payload],
        filtered_products: [...payload],
      };
    case SET_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_RANGE:
      return {
        ...state,
        range: payload,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case SET_BEDROOMS:
      return {
        ...state,
        bedrooms: payload,
      };
    case SET_BATHROOMS:
      return {
        ...state,
        bathrooms: payload,
      };
    case SET_COUNTY:
      return {
        ...state,
        county: payload,
      };
    case SET_STATES:
      return {
        ...state,
        states: payload,
      };
    case SET_SORT:
      return {
        ...state,
        sort: payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        country: payload,
      };
    case FILTER_PRODUCTS: {
      const { all_products } = state;
      const {
        search,
        country,
        bedrooms,
        bathrooms,
        states,
        county,
        range,
        sort,
      } = state;
      let tempProducts = [...all_products];
      console.log("tempProducts :", tempProducts);
      if (search !== "") {
        tempProducts = tempProducts.filter((product) => {
          return (
            product.home.address.toLowerCase().startsWith(search) ||
            product.home.country.toLowerCase().startsWith(search) ||
            product.home.county.toLowerCase().startsWith(search) ||
            product.home.state.toLowerCase().startsWith(search) ||
            product.home.title.toLowerCase().startsWith(search)
          );
        });
      }
      if (country !== "") {
        tempProducts = tempProducts.filter((product) => {
          return product.home.country === country;
        });
      }
      if (county !== "") {
        tempProducts = tempProducts.filter((product) => {
          return product.home.county === county;
        });
      }
      if (bedrooms !== 0) {
        tempProducts = tempProducts.filter((product) => {
          return product.home.countOfBadrooms === bedrooms;
        });
      }
      if (bathrooms !== 0) {
        console.log(tempProducts);
        tempProducts = tempProducts.filter((product) => {
          return product.home.countOfBathrooms === bathrooms;
        });
      }
      if (states !== "") {
        tempProducts = tempProducts.filter((product) => {
          return product.home.state === states;
        });
      }
      if (range) {
        tempProducts = tempProducts.filter((product) => {
          if (
            Number(range[0]) < Number(product.home?.purchasePrice) &&
            Number(product.home.purchasePrice) < Number(range[1])
          ) {
            return product;
          }
        });
      }
      if (sort !== "") {
        if (sort === "price-lowest") {
          tempProducts = tempProducts.sort((a, b) => {
            if (a.home.purchasePrice < b.home.purchasePrice) {
              return -1;
            }
            if (a.home.purchasePrice > b.home.purchasePrice) {
              return 1;
            }
            return 0;
          });
        }
        if (sort === "price-highest") {
          tempProducts = tempProducts.sort(
            (a, b) => b.home.purchasePrice - a.home.purchasePrice
          );
        }
      }
      return { ...state, filtered_products: tempProducts };
    }

    case CLEAR_CONTROLS:
      return initialState;
    default:
      return state;
  }
};
