import React from "react"
import BathroomsSelect from "../bathrooms-select/BathroomsSelect"
import BedroomsSelect from "../bedrooms-select/BedroomsSelect"
import CountrySelect from "../country-select/CountrySelect"
import CountySelect from "../county-select/CountySelect"
import Range from "../range/Range"
import Row from "../row/Row"
import Search from "../search/Search"
import SortSelect from "../sort-select/SortSelect"
import StateSelect from "../state-select/StateSelect"
import "./filter.scss"

const Filter = () => {
	return (
		<div className="filter">
			<div className="search-row">
				<Search />
			</div>
			<Row col="col-4">
				<div className="col">
					<BedroomsSelect />
				</div>
				<div className="col">
					<BathroomsSelect />
				</div>
				<div className="col">
					<CountySelect />
				</div>
				<div className="col">
					<StateSelect />
				</div>
				<div className="col">
					<CountrySelect />
				</div>
				<div className="col">
					<Range />
				</div>
				<div className="col">
					<SortSelect />
				</div>
			</Row>
		</div>
	)
}

export default Filter
