import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter, setSearch } from "../../store/controls/controls-action";
import { selectSearch } from "../../store/controls/controls-select";
import "./search.scss";

function Search() {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value));
    dispatch(setFilter());
  };
  return (
    <div className="search-input">
      <input
        type="search"
        placeholder="Enter address, community, city, state, country or ZIP code"
        value={search}
        onChange={handleSearch}
      />
    </div>
  );
}

export default Search;
